import { ViewportProvider } from 'context/viewportProvider'
import { ToastContainer } from 'react-toastify'
import Navigation from 'routes/Navigation'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import 'react-tooltip/dist/react-tooltip.css'
import 'react-toastify/dist/ReactToastify.css'

function App() {
  return (
    <ViewportProvider>
      <Navigation />
      <ToastContainer />
    </ViewportProvider>
  )
}

export default App
