import { ButtonVariants, Languages } from '../../constants'
import Hamburguer from '../Atoms/Buttons/Hamburguer/Hamburguer'
import Link from '../Atoms/Link/Link'
import LinkFlag from '../Molecules/LinkFlag'

import { useEffect, useState } from 'react'

import classNames from 'classnames'
import RoundedButton from 'components/Atoms/Buttons/RoundedButton/RoundedButton'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink, useLocation } from 'react-router-dom'

export interface HeaderProps {
  selected?: string
  onClick: (section: string) => void
  logo: string
  className?: string
  hideOptions?: boolean
  hideButton?: boolean
}

const Header = ({
  selected,
  onClick,
  logo,
  className,
  hideOptions,
  hideButton,
}: HeaderProps) => {
  const [t, i18n] = useTranslation('global')
  const language = i18n.language
  const [isOpen, setIsOpen] = useState(false)
  const navClassname = classNames('bg-white', 'p-4', {
    'flex flex-col flex-grow pb-4 md:pb-0 md:flex md:justify-center md:flex-row':
      isOpen,
    'hidden md:flex md:flex-row md:justify-center flex-col md:align-center':
      !isOpen,
  })
  const linkClassname = classNames({
    'ml-7': !isOpen,
  })

  const closeMenu = () => {
    setIsOpen(false)
  }

  const location = useLocation()
  const lang = location.search.split('lang=')[1]

  useEffect(() => {
    if (lang === 'en') {
      i18n.changeLanguage('en')
    } else {
      i18n.changeLanguage('es')
    }
  }, [lang])

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        isOpen &&
        !event.target.closest('.nav-container') &&
        !event.target.closest('.hamburger-button')
      ) {
        closeMenu()
      }
    }
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [isOpen])

  const languageBtn = () => {
    if (language === Languages.ENGLISH) {
      return Languages.SPANISH
    } else {
      return Languages.ENGLISH
    }
  }

  const handleClick = (section: string) => {
    onClick(section)
    closeMenu()
  }
  return (
    <div className={className}>
      <div className="antialiased bg-white dark-mode:bg-white">
        <div className="w-full text-gray-700 bg-white dark-mode:text-gray-200 dark-mode:bg-white nav-container">
          <div className="flex flex-col max-w-screen-xl px-4 mx-auto md:items-center md:justify-between md:flex-row md:px-6 lg:px-8">
            <div className="flex flex-row items-center justify-between p-4">
              <RouterLink to="/">
                <img
                  alt="logo"
                  src={`${process.env.REACT_APP_URL_S3}/images/${logo}`}
                  width={152}
                  height={23}
                />
              </RouterLink>
              <Hamburguer
                className="md:hidden focus:outline-none focus:shadow-outline"
                isOpen={isOpen}
                setIsOpen={setIsOpen}
              />
            </div>
            <nav className={navClassname}>
              {!hideOptions && (
                <>
                  <Link
                    selected={selected === 'successStories'}
                    onClick={() => handleClick('successStories')}
                  >
                    {t('header.successStories')}
                  </Link>
                  <Link
                    selected={selected === 'benefits'}
                    className={linkClassname}
                    onClick={() => handleClick('benefits')}
                  >
                    {t('header.benefits')}
                  </Link>
                  <Link
                    selected={selected === 'solutions'}
                    className={linkClassname}
                    onClick={() => handleClick('solutions')}
                  >
                    {t('header.solutions')}
                  </Link>
                  <a
                    href="https://blog.ciclogreen.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="flex justify-center"
                  >
                    <Link
                      selected={selected === 'blog'}
                      className={linkClassname}
                      onClick={() => {}}
                    >
                      {t('header.blog')}
                    </Link>
                  </a>
                </>
              )}
              <div className="md:ml-10 flex justify-center">
                <div className="flex flex-row md:ml-3">
                  <LinkFlag
                    language={languageBtn()}
                    height={15}
                    onClick={() => i18n.changeLanguage(`${languageBtn()}`)}
                  />
                </div>
              </div>
              {!hideButton && (
                <a
                  className={`${linkClassname} md:mt-0 mt-5 w-full md:w-auto flex justify-center`}
                  href="https://backoffice.cclgrn.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <RoundedButton
                    variant={ButtonVariants.GRADIENT}
                    className={isOpen ? 'mt-2' : 'mt-0'}
                  >
                    {t('btn.dashboardAccess')}
                  </RoundedButton>
                </a>
              )}
            </nav>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
