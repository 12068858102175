import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

const ParticularTerms = () => {
  const { t } = useTranslation('privacy')
  const bodyClassname = classNames(
    'container',
    'mx-auto',
    'mt-24',
    'space-y-4',
    'text-center',
    'md:text-left',
    'md:space-y-10',
    'max-w-screen-xl',
    'md:px-28',
    'mb-24',
  )
  const subtitleClassName = classNames(
    'relative',
    'z-20',
    'w-full',
    'text-center',
    'lg:text-left',
    'text-xl',
    'sm:text-3xl',
    'font-semibold',
  )
  const points = ['3', '4']

  const formatText = (text: string) => {
    // Reemplaza "\n" con saltos de línea <br>
    const textWithLineBreaks = text.replace(/\n/g, '<br><br>')

    // Expresión regular para encontrar direcciones de correo electrónico en el texto
    const emailRegex = /([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4})/g

    // Reemplaza las direcciones de correo electrónico con enlaces de correo
    const textWithEmailLinks = textWithLineBreaks.replace(emailRegex, email => {
      return `<a href="mailto:${email}" style="text-decoration: underline;">${email}</a>`
    })

    // Expresión regular para encontrar URLs en el texto
    const urlRegex = /(https?:\/\/[^\s]+)/g

    // Reemplaza las URLs con enlaces web
    const formattedText = textWithEmailLinks.replace(urlRegex, url => {
      return `<a href="${url}" target="_blank" rel="noopener noreferrer" style="text-decoration: underline;">${url}</a>`
    })

    // Utiliza dangerouslySetInnerHTML para renderizar HTML generado
    return <span dangerouslySetInnerHTML={{ __html: formattedText }} />
  }

  return (
    <>
      <div className={bodyClassname}>
        <h2 className="font-bold text-blue-800 text-2xl sm:text-5xl max-md:text-center">
          {t('title')}
        </h2>
        <h3 className={subtitleClassName}>{t('subtitle1')}</h3>
        <div className="text-gray-500">{formatText(t('text1'))}</div>
        {points.map((point, index) => (
          <div key={index}>
            <h3 className={subtitleClassName}>
              {t(`subtitle${point}`, { item: Number(point) - 1 })}
            </h3>
            <div className="text-gray-500 mt-6">
              {formatText(t(`text${point}`))}
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default ParticularTerms
