import ResetPasswordView from './ResetPasswordView'
import useResetPassword from './useResetPassword'

import React from 'react'

import { withHook } from 'utilities'

const ResetPassword: React.FC<any> = withHook({
  hook: useResetPassword,
  component: ResetPasswordView,
})
export default ResetPassword
