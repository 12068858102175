import { Testimonials } from 'models/Client'

export const hightlightCompanies: string[] = [
  'fagor',
  'ulma',
  'renfe',
  'bbva',
  'caf',
  'cosentino',
  'iberostar',
  'norauto',
  'securitas',
]

export const ourClients: string[] = [
  'fagor',
  'ulma',
  'renfe',
  'bbva',
  'iberostar',
  'securitas',
  'cosentino',
  'caf',
  'norauto',
  'mahou',
  // 'uva',
  'talgo',
  // 'uma',
  'reale',
  // 'ule',
  'basf',
  // 'unavarra',
  'tragsa',
  // 'uja',
  'isdefe',
  'ugranada',
  'uab',
  'portBalears',
  // 'pct',
  'navantia',
  'ccoo',
  'ayesa',
  'ajuntamentBarcelona',
  'amaya',
  'amb',
  'oreka',
  'ajuntamentBenidorm',
  // 'navantia',
  'ule',
  // 'isdefe',
  'uva',
  'atexis',
  'mondragon',
]

export const testimonials: Testimonials = {
  testimonials: [
    {
      client: {
        name: 'Lander Díaz de Gereñu',
        position: 'Presidente de la fundación y grupo ULMA',
        company: 'ULMA',
        avatar: 'lander-diaz',
        companyLogo: 'ulma',
      },
      mesagge:
        'Premiar la movilidad sostenible en ULMA está haciendo que los aparcamientos para bicis se nos estén quedando pequeños. Nuestras personas están viviendo el reto y muchas ya han comprobado que venir a trabajar de forma sostenible no es tan difícil y en muchos casos resulta más cómodo y rápido que utilizar el coche individual. Ahora tenemos que gestionar nuestros aparcamientos para adecuarlos a la movilidad sostenible, y esto es muy positivo desde todos los puntos de vista.',
    },
    {
      client: {
        name: 'Pablo Olivares Phélix',
        position: 'Ingeniero de caminos del área de movilidad y sostenibilidad',
        company: 'AOPJA',
        avatar: 'pablo-olivares',
        companyLogo: 'aopja',
      },
      mesagge:
        'Lo más importante del Plan de Movilidad Sostenible ha sido como, gracias al apoyo de la aplicación de Ciclogreen, los compañeros de la Agencia de Obra Pública de la Junta de Andalucía han asumido la movilidad sostenible como algo positivo para ellos y para nuestro entorno social y medioambiental, más allá de los motivos iniciales de cada uno (retos y premios de Ciclogreen, ahorro de tiempo y coste, salud y bienestar, etc.), convirtiéndolo finalmente en un hábito. “Los temas de movilidad no son temas técnicos sino vitales.',
    },
    {
      client: {
        name: 'José Antonio Santoyo Román',
        position: 'Investigador de Smart-Campus',
        company: 'Universidad de Málaga',
        avatar: 'joseantoniosantoyo-uma',
        companyLogo: 'uma',
      },
      mesagge:
        'La implantación de Ciclogreen en la Universidad ha originado una mayor concienciación en la movilidad del Campus, además, la gamificación de la app ha generado que la Comunidad Universitaria se percate de que realmente es una parte importante en la lucha del cambio climático.',
    },
  ],
}

export const gridSustainability: any[] = [
  {
    img: 'co2_grid',
    title: 'titleCo2',
    text: 'textCo2',
  },
  {
    img: 'law_grid',
    title: 'titleLaw',
    text: 'textLaw',
  },
  {
    img: 'ptt_grid',
    title: 'titlePtt',
    text: 'textPtt',
  },
  {
    img: 'target_grid',
    title: 'titleTarget',
    text: 'textTarget',
  },
]

export const odsColumns: string[] = ['11', '13']

export const solutionsMock: string[] = ['universities', 'companies', 'cities']

export const hightlightPartners: string[] = [
  'horizon',
  'frontier',
  'fiware',
  'enisa',
  'eit',
  'edi',
]
