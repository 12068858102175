import React from 'react'

import classNames from 'classnames'
import Footer from 'components/Organisms/Footer'
import { useTranslation } from 'react-i18next'

const LegalNotice = () => {
  const { t } = useTranslation('legal')
  const documentRef = React.useRef<HTMLDivElement>(null)
  const bodyClassname = classNames(
    'container',
    'mx-auto',
    'mt-24',
    'space-y-4',
    'text-center',
    'md:text-left',
    'md:space-y-10',
    'max-w-screen-xl',
    'md:px-28',
  )
  const titleGCClassName = classNames(
    'relative',
    'z-20',
    'w-full',
    'text-center',
    'lg:text-left',
    'text-xl',
    'sm:text-3xl',
    'font-semibold',
  )
  const subtitleClassName = classNames(
    'relative',
    'z-20',
    'w-full',
    'text-center',
    'lg:text-left',
    'text-xl',
    'sm:text-3xl',
    'font-semibold',
  )

  const firtsPoints = ['1', '2', '3', '4']
  const secondPoints = [
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
  ]
  const items = ['1', '2', '3', '4']
  const items2 = ['1', '2', '3']

  const formatText = (text: string) => {
    // Reemplaza "\n" con saltos de línea <br>
    const textWithLineBreaks = text.replace(/\n/g, '<br><br>')

    // Expresión regular para encontrar direcciones de correo electrónico en el texto
    const emailRegex = /([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4})/g

    // Reemplaza las direcciones de correo electrónico con enlaces de correo
    const textWithEmailLinks = textWithLineBreaks.replace(emailRegex, email => {
      return `<a href="mailto:${email}" style="text-decoration: underline;">${email}</a>`
    })

    // Expresión regular para encontrar URLs en el texto
    const urlRegex = /(https?:\/\/[^\s]+)/g

    // Reemplaza las URLs con enlaces web
    const formattedText = textWithEmailLinks.replace(urlRegex, url => {
      return `<a href="${url}" target="_blank" rel="noopener noreferrer" style="text-decoration: underline;">${url}</a>`
    })

    // Utiliza dangerouslySetInnerHTML para renderizar HTML generado
    return <span dangerouslySetInnerHTML={{ __html: formattedText }} />
  }

  return (
    <>
      <div className={bodyClassname} ref={documentRef}>
        <h2 className="font-bold text-blue-800 text-2xl sm:text-5xl max-md:text-center">
          {t('title')}
        </h2>
        <div className="text-gray-500">{formatText(t('text'))}</div>
        <h3 className={titleGCClassName}>{t('titleGC')}</h3>
        {firtsPoints.map((point, index) => (
          <div key={index}>
            <h3 className={subtitleClassName}>
              {point + '. ' + t(`subtitle${point}`)}
            </h3>
            <div className="text-gray-500 mt-6">
              {formatText(t(`text${point}`))}
            </div>
          </div>
        ))}
        <h3 className={subtitleClassName}>{'5. ' + t('subtitle5')}</h3>
        <div className="text-gray-500">{formatText(t('text5'))}</div>
        {items.map((item, index) => (
          <div key={index}>
            <span className="text-gray-500">
              <b>
                5.{item}. <span className="underline">{t(`item${item}`)}</span>
              </b>
              <p className="mt-2">{formatText(t(`text5_${item}`))}</p>
            </span>
          </div>
        ))}
        <h3 className={subtitleClassName}>{'6. ' + t('subtitle6')}</h3>
        {items2.map((item, index) => (
          <div key={index}>
            <span className="text-gray-500">
              <b>
                6.{item}.{' '}
                <span className="underline">{t(`item${item}_2`)}</span>
              </b>
              <p className="mt-2">{formatText(t(`text6_${item}`))}</p>
            </span>
          </div>
        ))}
        {secondPoints.map((point, index) => (
          <div key={index}>
            <h3 className={subtitleClassName}>
              {point + '. ' + t(`subtitle${point}`)}
            </h3>
            <div className="text-gray-500 mt-6">
              {formatText(t(`text${point}`))}
            </div>
          </div>
        ))}
      </div>
      <Footer />
    </>
  )
}

export default LegalNotice
