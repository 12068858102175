import { Route } from '../models'

import React from 'react'

import ExternalLayout from 'layouts/ExternalLayout'
import HomeLayout from 'layouts/HomeLayout'
import ConfirmPassword from 'pages/ConfirmPassword'
import CookiesPolicy from 'pages/CookiesPolicy'
import LegalNotice from 'pages/LegalNotice'
import ParticularTerms from 'pages/ParticularTerms'
import PrivacyPolicy from 'pages/PrivacyPolicy'
import ResetPassword from 'pages/ResetPassword'

const Home = React.lazy(() => import('../pages/Home'))
const Error = React.lazy(() => import('../pages/Error'))

export const routes: Route[] = [
  {
    path: '/',
    page: Home,
    layout: HomeLayout,
  },
  {
    path: '/terms',
    page: PrivacyPolicy,
    layout: ExternalLayout,
  },
  {
    path: '/terms_app',
    page: ParticularTerms,
    layout: undefined,
  },
  {
    path: '/legal',
    page: LegalNotice,
    layout: ExternalLayout,
  },
  {
    path: '/cookies-policy',
    page: CookiesPolicy,
    layout: ExternalLayout,
  },
  {
    path: 'user/reset-password/:userUUID/:token',
    page: ResetPassword,
    layout: undefined,
  },
  {
    path: 'user/password-confirm',
    page: ConfirmPassword,
    layout: undefined,
  },
  {
    path: '*',
    page: Error,
    layout: HomeLayout,
  },
]
