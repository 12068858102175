import classNames from 'classnames'
import Footer from 'components/Organisms/Footer'
import { useTranslation } from 'react-i18next'
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'

const CookiesPolicy = () => {
  const { t } = useTranslation('cookies')
  const bodyClassname = classNames(
    'container',
    'mx-auto',
    'mt-24',
    'space-y-4',
    'text-center',
    'md:text-left',
    'md:space-y-10',
    'max-w-screen-xl',
    'px-4',
    'md:px-28',
  )
  const subtitleClassName = classNames(
    'relative',
    'z-20',
    'w-full',
    'text-center',
    'lg:text-left',
    'text-xl',
    'sm:text-3xl',
    'font-semibold',
  )

  const firtsPoints = ['1', '2', '3']
  const items = ['A', 'B', 'C']
  const firstTableRows = ['1', '2']
  const secondTableRows = ['1', '2', '3', '4', '5', '6', '7', '8']

  const formatText = (text: string) => {
    // Reemplaza "\n" con saltos de línea <br>
    const textWithLineBreaks = text.replace(/\n/g, '<br><br>')

    // Expresión regular para encontrar direcciones de correo electrónico en el texto
    const emailRegex = /([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4})/g

    // Reemplaza las direcciones de correo electrónico con enlaces de correo
    const textWithEmailLinks = textWithLineBreaks.replace(emailRegex, email => {
      return `<a href="mailto:${email}" style="text-decoration: underline;">${email}</a>`
    })

    // Expresión regular para encontrar URLs en el texto
    const urlRegex = /(https?:\/\/[^\s]+)/g

    // Reemplaza las URLs con enlaces web
    const formattedText = textWithEmailLinks.replace(urlRegex, url => {
      return `<a href="${url}" target="_blank" rel="noopener noreferrer" style="text-decoration: underline;">${url}</a>`
    })

    // Utiliza dangerouslySetInnerHTML para renderizar HTML generado
    return <span dangerouslySetInnerHTML={{ __html: formattedText }} />
  }

  return (
    <>
      <div className={bodyClassname}>
        <h2 className="font-bold text-blue-800 text-2xl sm:text-5xl max-md:text-center">
          {t('title')}
        </h2>
        <div className="text-gray-500">{formatText(t('text'))}</div>
        {firtsPoints.map((point, index) => (
          <div key={index}>
            <h3 className={subtitleClassName}>
              {point + '. ' + t(`subtitle${point}`)}
            </h3>
            <div className="text-gray-500 mt-6">
              {formatText(t(`text${point}`))}
            </div>
          </div>
        ))}
        {items.map((item, index) => (
          <div key={index}>
            <span className="text-gray-500">
              <b>
                {item.toLowerCase()}.{' '}
                <span className="underline">{t(`item${item}`)}</span>:{' '}
              </b>
              <span className="mt-2">{formatText(t(`text${item}`))}</span>
            </span>
          </div>
        ))}
        <div className="text-gray-500">{formatText(t('text3_2'))}</div>
        <h3 className="relative z-10 uppercase font-semibold text-blue-800 sm:text-lg">
          {t('tableOwner.title')}
        </h3>
        <Table>
          <Thead className="bg-gray-200 md:border text-center">
            <Tr className="md:border">
              <Th className="md:p-1">{t('tableOwner.head.col1')}</Th>
              <Th className="border-x md:p-1">{t('tableOwner.head.col2')}</Th>
              <Th className="md:p-1">{t('tableOwner.head.col3')}</Th>
              <Th className="border-x md:p-1">{t('tableOwner.head.col4')}</Th>
              <Th className="md:p-1">{t('tableOwner.head.col5')}</Th>
            </Tr>
          </Thead>
          <Tbody className="md:border max-sm:w-[343px]">
            {firstTableRows.map((row, index) => (
              <Tr key={index} className="md:border">
                <Td className="p-1">{t(`tableOwner.row${row}.col1`)}</Td>
                <Td className="md:border-x p-1">
                  {t(`tableOwner.row${row}.col2`)}
                </Td>
                <Td className="p-1">{t(`tableOwner.row${row}.col3`)}</Td>
                <Td className="md:border-x p-1">
                  {t(`tableOwner.row${row}.col4`)}
                </Td>
                <Td className="p-1">{t(`tableOwner.row${row}.col5`)}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <h3 className="relative z-10 uppercase font-semibold text-blue-800 sm:text-lg">
          {t('tableThird.title')}
        </h3>
        <Table>
          <Thead className="bg-gray-200 md:border text-center">
            <Tr className="md:border">
              <Th className="md:p-1">{t('tableThird.head.col1')}</Th>
              <Th className="md:border-x md:p-1">
                {t('tableThird.head.col2')}
              </Th>
              <Th className="md:p-1">{t('tableThird.head.col3')}</Th>
              <Th className="md:border-x md:p-1">
                {t('tableThird.head.col4')}
              </Th>
              <Th className="md:p-1">{t('tableThird.head.col5')}</Th>
            </Tr>
          </Thead>
          <Tbody className="md:border max-sm:w-[343px]">
            {secondTableRows.map((row, index) => (
              <Tr key={index} className="md:border">
                <Td className="md:p-1">{t(`tableThird.row${row}.col1`)}</Td>
                <Td className="md:border-x md:p-1">
                  {formatText(t(`tableThird.row${row}.col2`))}
                </Td>
                <Td className="md:p-1">{t(`tableThird.row${row}.col3`)}</Td>
                <Td className="md:border-x md:p-1">
                  {t(`tableThird.row${row}.col4`)}
                </Td>
                <Td className="md:p-1">{t(`tableThird.row${row}.col5`)}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <span>{t('extraInfo')}</span>
        <div className="text-gray-500">{formatText(t('text3_3'))}</div>
        <h3 className={subtitleClassName}>{'4. ' + t(`subtitle4`)}</h3>
        <div className="text-gray-500 mt-6">{formatText(t(`text4`))}</div>
      </div>
      <Footer />
    </>
  )
}

export default CookiesPolicy
