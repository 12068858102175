import { useState } from 'react'

import { useFetchAndLoad } from 'hooks'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { resetPassword } from 'services/auth.service'
import * as Yup from 'yup'

export interface ResetPasswordProps {
  handleSubmit: (values: any, actions: any) => void
  passwordSchema: any
  passwordStrength: {
    length: boolean
    uppercase: boolean
    number: boolean
    special: boolean
  }
  checkPasswordStrength: (password: string) => void
  t: any
}

const useResetPassword = () => {
  const [t, i18n] = useTranslation('pass-reset')
  const { callEndpoint } = useFetchAndLoad()
  const { userUUID, token } = useParams()
  const navigate = useNavigate()
  const [passwordStrength, setPasswordStrength] = useState({
    length: false,
    uppercase: false,
    number: false,
    special: false,
  })

  const passwordSchema: Yup.Schema = Yup.object().shape({
    password: Yup.string()
      .min(8, t('8characters'))
      .matches(/[A-Z]/, t('uppercase'))
      .matches(/\d/, t('number'))
      .matches(/[!@#$%^&*(),.?":{}|<>]/, t('special'))
      .required(t('required')),
    confirmPassword: Yup.string()
      .test('passwords-match', t('passwordsNotMatch'), function (value) {
        return this.parent.password === value
      })
      .required(t('required')),
  })

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    try {
      await callEndpoint(resetPassword(values.password, userUUID!, token!))
      navigate(`/user/password-confirm/?lang=${i18n.language}`)
    } catch (error: any) {
      const response = error.response
      if (
        response &&
        response?.data &&
        response?.data?.token &&
        Array.isArray(response.data.token) &&
        response?.data?.token?.includes('Invalid value')
      ) {
        toast.error(t('errors.sessionExpired'), { theme: 'colored' })
      } else if (
        response &&
        response?.data &&
        response?.data?.new_password2 &&
        Array.isArray(response.data.new_password2)
      ) {
        toast.error(t('errors.similarPassword'), { theme: 'colored' })
      } else {
        toast.error(t('errors.serverError'), { theme: 'colored' })
      }
    }
    setSubmitting(false)
  }

  const checkPasswordStrength = (password: string) => {
    setPasswordStrength({
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      number: /\d/.test(password),
      special: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    })
  }

  return {
    handleSubmit,
    passwordSchema,
    passwordStrength,
    checkPasswordStrength,
    t,
  }
}

export default useResetPassword
