import axios from 'axios'
import { loadAbort } from 'utilities'

export const resetPassword = (
  password: string,
  userUUID: string,
  token: string,
) => {
  const controller = loadAbort()
  const url = `${process.env.REACT_APP_BASE_URL}/api/auth/password/reset/confirm/`
  return {
    call: axios.post(
      url,
      {
        new_password1: password,
        new_password2: password,
        uid: userUUID,
        token,
      },
      { signal: controller.signal },
    ),
    controller,
  }
}
