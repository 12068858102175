import classNames from 'classnames'
import Header from 'components/Organisms/Header'
import { useTranslation } from 'react-i18next'
import { FaCheckCircle } from 'react-icons/fa'

const ConfirmPassword = () => {
  const { t } = useTranslation('pass-reset')
  const handleCloseWindow = () => {
    window.close()
    setTimeout(() => {
      alert(t('confirmation.alert'))
    }, 300)
  }

  const buttonClass = classNames(
    'flex',
    'justify-center',
    'py-2',
    'px-4',
    'rounded-2xl',
    'shadow-sm',
    'font-semibold',
    'text-white',
    'bg-black',
    'mt-4',
  )

  return (
    <>
      <Header logo="logo.png" onClick={() => {}} hideOptions hideButton />
      <div className="flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 md:shadow sm:rounded-lg sm:px-10">
            <div className="text-center">
              <FaCheckCircle className="mx-auto h-12 w-12 text-green-600" />
              <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                {t('confirmation.title')}
              </h2>
              <p className="mt-2 text-center text-sm text-gray-600">
                {t('confirmation.text')}
              </p>
              <div className="mt-8 w-full flex flex-col items-center">
                <p className="text-sm text-gray-500">
                  {t('confirmation.closeWindow')}
                </p>
                <button onClick={handleCloseWindow} className={buttonClass}>
                  {t('btn.closeWindow')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ConfirmPassword
