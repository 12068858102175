import { ResetPasswordProps } from './useResetPassword'

import classNames from 'classnames'
import Header from 'components/Organisms/Header'
import { Field, Form, Formik } from 'formik'
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa'

const ResetPasswordView = ({
  handleSubmit,
  passwordSchema,
  passwordStrength,
  checkPasswordStrength,
  t,
}: ResetPasswordProps) => {
  const buttonClass = (errors: any) => {
    const disabledButton =
      errors?.password?.length! > 0 || errors.confirmPassword?.length! > 0
    return classNames(
      'flex',
      'justify-center',
      'py-2',
      'px-4',
      'rounded-2xl',
      'shadow-sm',
      'font-semibold',
      'text-white',
      'bg-black',
      { 'bg-grey-600': disabledButton },
    )
  }

  return (
    <>
      <Header logo="logo.png" onClick={() => {}} hideOptions hideButton />
      <div className="flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 md:shadow sm:rounded-lg sm:px-10">
            <h1 className="mb-6 w-full text-center font-semibold">
              {t('title')}
            </h1>
            <Formik
              initialValues={{ password: '', confirmPassword: '' }}
              validationSchema={passwordSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, values, handleChange }) => (
                <Form className="space-y-6">
                  <div>
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t('password')}
                    </label>
                    <div className="mt-1">
                      <Field
                        type="password"
                        name="password"
                        id="password"
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        value={values.password}
                        onChange={(e: any) => {
                          handleChange(e)
                          checkPasswordStrength(e.target.value)
                        }}
                      />
                    </div>
                    {errors.password && touched.password ? (
                      <div className="text-red-500 text-sm mt-1">
                        {errors.password}
                      </div>
                    ) : null}
                  </div>

                  <div>
                    <label
                      htmlFor="confirmPassword"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t('confirmPassword')}
                    </label>
                    <div className="mt-1">
                      <Field
                        type="password"
                        name="confirmPassword"
                        id="confirmPassword"
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                    {errors.confirmPassword && touched.confirmPassword ? (
                      <div className="text-red-500 text-sm mt-1">
                        {errors.confirmPassword}
                      </div>
                    ) : null}
                  </div>

                  <div className="space-y-2">
                    <p className="text-sm font-medium text-gray-700">
                      {t('requerinments')}
                    </p>
                    <ul className="space-y-1">
                      <li
                        className={`text-sm ${
                          passwordStrength.length
                            ? 'text-green-600'
                            : 'text-red-600'
                        }`}
                      >
                        {passwordStrength.length ? (
                          <FaCheckCircle className="inline-block w-4 h-4 mr-1" />
                        ) : (
                          <FaTimesCircle className="inline-block w-4 h-4 mr-1" />
                        )}
                        {t('8characters')}
                      </li>
                      <li
                        className={`text-sm ${
                          passwordStrength.uppercase
                            ? 'text-green-600'
                            : 'text-red-600'
                        }`}
                      >
                        {passwordStrength.uppercase ? (
                          <FaCheckCircle className="inline-block w-4 h-4 mr-1" />
                        ) : (
                          <FaTimesCircle className="inline-block w-4 h-4 mr-1" />
                        )}
                        {t('uppercase')}
                      </li>
                      <li
                        className={`text-sm ${
                          passwordStrength.number
                            ? 'text-green-600'
                            : 'text-red-600'
                        }`}
                      >
                        {passwordStrength.number ? (
                          <FaCheckCircle className="inline-block w-4 h-4 mr-1" />
                        ) : (
                          <FaTimesCircle className="inline-block w-4 h-4 mr-1" />
                        )}
                        {t('number')}
                      </li>
                      <li
                        className={`text-sm ${
                          passwordStrength.special
                            ? 'text-green-600'
                            : 'text-red-600'
                        }`}
                      >
                        {passwordStrength.special ? (
                          <FaCheckCircle className="inline-block w-4 h-4 mr-1" />
                        ) : (
                          <FaTimesCircle className="inline-block w-4 h-4 mr-1" />
                        )}
                        {t('special')}
                      </li>
                    </ul>
                  </div>

                  <div className="w-full flex justify-center">
                    <button
                      type="submit"
                      className={buttonClass(errors)}
                      disabled={
                        errors?.password?.length! > 0 ||
                        errors.confirmPassword?.length! > 0
                      }
                    >
                      {t('btn.resetPassword')}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  )
}
export default ResetPasswordView
