import classNames from 'classnames'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { BsFacebook, BsLinkedin } from 'react-icons/bs'
import { RiTwitterXFill } from 'react-icons/ri'
import { Link } from 'react-router-dom'

export interface Contact {
  name: string
  email: string
  subject: string
  message: string
}

const Footer = () => {
  const [t, i18n] = useTranslation('global')
  const bodyClassname = classNames(
    'container',
    'mx-auto',
    'space-y-4',
    'text-center',
    'md:text-left',
    'md:space-y-10',
    'max-w-screen-xl',
    'md:px-28',
    'mt-10',
    'mb-4',
  )
  return (
    <div className={bodyClassname}>
      <div className="flex flex-col max-sm:space-y-6 lg:flex-row lg:justify-between items-center text-gray-500 text-xs">
        <span>{`© Copyright Ciclogreen ${moment().format('YYYY')}`}</span>
        <div className="flex flex-row space-x-2 items-center max-lg:justify-center">
          <a href="https://play.google.com/store/apps/details?id=es.inmovens.ciclogreen&hl=es&pli=1&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
            <img
              alt="Badge Google Play"
              src={`https://play.google.com/intl/en_us/badges/static/images/badges/${i18n.language}_badge_web_generic.png`}
              className={i18n.language === 'en' ? 'h-[60px]' : 'h-[50px]'}
            />
          </a>
          <a
            href="https://apps.apple.com/us/app/ciclogreen-sustanaiblemobility/id1326776999?itsct=apps_box_badge&amp;itscg=30200"
            style={{
              display: 'inline-block',
              overflow: 'hidden',
              borderRadius: '13px',
            }}
            className="h-[40px]"
          >
            <img
              src={`https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/${i18n.language}-${i18n.language}?releaseDate=1515715200`}
              alt="App Store"
              style={{
                borderRadius: '13px',
              }}
              className="h-[40px]"
            />
          </a>
        </div>
        <div className="flex space-x-2 h-full">
          <a href="https://facebook.com/Ciclogreenapp/" target="_blanc">
            <BsFacebook size={20} color="text-gray-500" />
          </a>
          <a href="https://twitter.com/ciclogreen?lang=es" target="_blanc">
            <RiTwitterXFill size={20} color="text-gray-500" />
          </a>
          <a href="https://www.linkedin.com/company/ciclogreen" target="_blanc">
            <BsLinkedin size={20} color="text-gray-500" />
          </a>
        </div>
        <div className="flex gap-4 underline justify-center items-center flex-wrap px-6">
          <div>
            <a
              href="https://www.dropbox.com/sh/f975phuz8f23jkd/AACx1zGl1A_nIXcYFsDBVhtia?dl=0"
              target="_blank"
              rel="noreferrer"
            >
              {t('footer.pressRoom')}
            </a>
          </div>
          <Link to={'/terms'} target="_blank">
            {t('footer.privacyPolicy')}
          </Link>
          <Link to={'/legal'} target="_blank">
            {t('footer.legalAdvice')}
          </Link>
          <Link to={'/cookies-policy'} target="_blank">
            {t('footer.cookiesPolicy')}
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Footer
